@import "~ng-zorro-antd/ng-zorro-antd.min.css";

/* << styles    */
.text-xl {
  width: 325px;
  display: inline-block;
}
.text-lg {
  width: 130px;
  display: inline-block;
}
.text-md {
  width: 90px;
  display: inline-block;
}
.text-sm {
  width: 54px;
  display: inline-block;
}


.ant-form-item {
  margin-bottom: 12px !important;
}

.ant-list-lg .ant-list-item {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: lightgray !important;
}

.ant-modal {
  width: 840px !important;
}

nz-upload {
  width: 100% !important;
}

a {
  line-height: 32px;
}

.ant-checkbox-wrapper {
  line-height: 32px !important;
}

.save-button {
  margin: 0;
  margin-top: 16px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

span.placeholder.ck-widget {
  background: #ffff00;
  padding: 4px 2px;
  outline-offset: -2px;
  line-height: 1em;
  margin: 0 1px;
}
span.placeholder.ck-widget::selection {
  display: none;
}

span.if.ck-widget {
  background: red;
  padding: 4px 2px;
  outline-offset: -2px;
  line-height: 1em;
  margin: 0 1px;
}
span.if.ck-widget::selection {
  display: none;
}

span.else.ck-widget {
  background: red;
  padding: 4px 2px;
  outline-offset: -2px;
  line-height: 1em;
  margin: 0 1px;
}
span.else.ck-widget::selection {
  display: none;
}

span.end.ck-widget {
  background: red;
  padding: 4px 2px;
  outline-offset: -2px;
  line-height: 1em;
  margin: 0 1px;
}
span.end.ck-widget::selection {
  display: none;
}

.document-editor {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);
  display: flex;
  flex-flow: column nowrap;
}


/*    styles >> */
